import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Case } from '../../sections/index'

import Layout from '../../layout/Layout'
import Seo from '../../components/Seo'
import hero from '../../images/banners/allency-banner-briefcase.png'
import SmallHeroBand from '../../sections/SmallHeroBand'
import { SmallBand } from '../../sections/BandSection'


const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {

      caseOne: file(relativePath: { eq: "stock/dental-doctors-1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseTwo: file(relativePath: { eq: "stock/dental-doctors-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseThree: file(relativePath: { eq: "stock/dental-doctors-3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

    }
  `)

  const caseOne = dataImage.caseOne.childImageSharp.gatsbyImageData
  const caseTwo = dataImage.caseTwo.childImageSharp.gatsbyImageData
  const caseThree = dataImage.caseThree.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Portafolio',
    },
    case: {
      title: 'Dental Doctors',
      services: 'Mkt digital',
      description: `En Dental Doctor's nos dimos la tarea de definir estrategias para la creación de contenido, manteniendo a su audiencia activa en redes sociales y generando presencia para la marca.`,
      images: [caseOne,caseTwo,caseThree ]
    }
  }
  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Allency'}
        description={"Agencia de publicidad"}
        keywords={['Publicidad,Aliados,Estrategia,Marketing,Agencia,Digital,Resultados,Branding,Naming,Logotipo,Brochure,Creativo,Plan,Medios,Campañas,Programación,Código,Posicionamiento,Tráfico,Interfaces,Gestión,Multimedia,Diseño,Proyecto,Planes,Soluciones,Calidad,Startups,Pymes,Empresa,Eventos,Corporativos,Marcas,Portafolio']}
        noIndex={''}
        noFollow={''}
        slug={'dental-doctors'}
        canonicalUrl={'https://allency.com.mx/portfolio/dental-doctors'}
        ogTitle={'Allency'}
        ogType={'website'}
        ogDescription={"Agencia de publicidad"}
        ogImage={'../../images/logos/allency-dark.png'}
      />

      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Case contents={contents.case} />
      <SmallBand />
    </Layout>
  )
}

export default IndexPage